<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <v-card-title>
          Zgłoszenie
          <v-spacer></v-spacer>
        </v-card-title>

        <v-expansion-panels multiple v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header> Podsumowanie zgłoszenia </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Treść zgłoszenia:</v-list-item-title>
                      <v-list-item-subtitle>{{ ticket.content }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Data aktualizacji:</v-list-item-title>
                      <v-list-item-subtitle>{{ ticket.updated }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Numer transportu:</v-list-item-title>
                      <v-list-item-subtitle>{{ transport.t_number }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-chip :color="getTicketStatusColor(ticket.status)" dark>{{
                    getTicketStatusName(ticket.status)
                  }}</v-chip>
                </v-col>
              </v-row>
              <v-row align="start" justify="space-between">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Załączniki:</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
              <div class="images">
                <div v-for="(item, index) in images" v-bind:key="index">
                  <a data-fslightbox="gallery" :href="item.blob">
                    <img class="ticketImage" v-bind:src="'data:image/jpeg;base64,' + item.base64" />
                  </a>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header> Wiadomości </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                align="start"
                justify="space-between"
                v-for="[key, item] in allMessages.entries()"
                :key="key"
              >
                <v-col cols="6">
                  <span v-if="isAdmin && key == 0">Twoje wiadomości</span>
                  <div v-if="item.admin">
                    <v-card class="mt-3 mb-3">
                      <v-card-subtitle>
                        {{ item.updated }}
                      </v-card-subtitle>
                      <v-card-text>
                        {{ item.content }}
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
                <v-col cols="6">
                  <span v-if="!isAdmin && key == 0">Twoje wiadomości</span>
                  <div v-if="!item.admin">
                    <v-card color="#385F73" dark class="mt-3 mb-3">
                      <v-card-subtitle>
                        {{ item.updated }}
                      </v-card-subtitle>
                      <v-card-text>
                        {{ item.content }}
                      </v-card-text>
                    </v-card>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer />
                <v-col md="6">
                  <v-textarea
                    v-model="content"
                    label="Treść wiadomości"
                    hint="treść wiadomości, którą chcesz dodać do tego zgłoszenia"
                  ></v-textarea>
                </v-col>
                <v-spacer />
              </v-row>
              <v-row>
                <v-spacer />
                <v-col md="2">
                  <v-spacer />
                  <v-btn
                    @click="sendMessage()"
                    :disabled="!content"
                    color="amber accent-3"
                    class="grey--text text--darken-4"
                    :x-large="true"
                    v-tilt
                    >Wyślij</v-btn
                  >
                </v-col>
                <v-spacer />
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header> Szczegóły zamówienia </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="start" justify="space-between">
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Zawartość:</v-list-item-title>
                      <v-list-item-subtitle>{{ transport.content }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="transport.params[0] && transport.params[0].width">
                    <v-list-item-content>
                      <v-list-item-title>Wymiary:</v-list-item-title>
                      <v-list-item-subtitle v-for="(item, index) in transport.params" :key="index">
                        <strong v-if="transport.params.length > 1"
                          ><br />Paczka {{ index + 1 }}:</strong
                        >
                        {{ item.width }}cm x {{ item.height }}cm x {{ item.depth }}cm
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line v-if="transport.params[0] && transport.params[0].weight">
                    <v-list-item-content>
                      <v-list-item-title>Waga:</v-list-item-title>
                      <v-list-item-subtitle v-for="(item, index) in transport.params" :key="index">
                        <strong v-if="transport.params.length > 1"
                          ><br />Paczka {{ index + 1 }}:</strong
                        >
                        {{ item.weight }}kg
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Waga:</v-list-item-title>
                      <v-list-item-subtitle>{{ transport.params.weight }}kg</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Cena transportu:</v-list-item-title>
                      <v-list-item-subtitle>{{ transport.price }} zł</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Dodatkowe informacje:</v-list-item-title>
                      <v-list-item-subtitle>{{ transport.info }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Dodano:</v-list-item-title>
                      <v-list-item-subtitle>{{ transport.created }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-chip :color="getStatusColor(transport.status)" dark>{{
                          getStatusName(transport.status)
                        }}</v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-bottom-navigation>
      <v-btn :to="{ name: 'ticketsList' }" value="recent">
        <span>Powrót do listy</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>

      <v-btn v-if="isAdmin" @click="overlay = !overlay">
        <span>Zmień status</span>
        <v-icon>mdi-auto-fix</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-overlay :value="overlay" :dark="false" :light="true">
      <v-toolbar>
        <v-spacer />
        <v-btn @click="overlay = !overlay" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <TicketStatus :ticket="ticket.ticket_id"></TicketStatus>
    </v-overlay>
  </v-container>
</template>

<script src='https://api.mapbox.com/mapbox-gl-js/v0.42.0/mapbox-gl.js'></script>
 <link href='https://api.mapbox.com/mapbox-gl-js/v0.42.0/mapbox-gl.css' rel='stylesheet' />
<script>
require('fslightbox');
import store from '../../store'
import TicketStatus from '../../components/TicketStatus.vue'

import {
  mdiPackageVariant,
  mdiPin,
  mdiContacts
} from '@mdi/js'

import axios from 'axios'

export default {
  props: {
    source: String,
  },
  components: {
    TicketStatus
  },
  data: () => ({
    icons: {
      mdiPackageVariant,
      mdiPin,
      mdiContacts
    },
    panels: [0,1],
    transport: {params:{}},
    ticket: {},
    images: [],
    messages: [],
    allMessages: [],
    overlay: false,
    content: null,
    isAdmin: store.getters.isAdmin
  }),
  methods: {
    getTicketStatusColor: function (status) {
      let r;
      switch( status ){
        case 0: r = 'blue'; break;
        case 1: r = 'orange'; break;
        case 2: r = 'green'; break;
        case 3: r = 'red'; break;
      }
      return r;
    },
    getTicketStatusName: function (status) {
      let r;
      switch( status ){
        case 0: r = 'Oczekuje na rozpatrzenie'; break;
        case 1: r = 'W trakcie rozpatrzenia'; break;
        case 2: r = 'Zaakceptowana'; break;
        case 3: r = 'Odrzucona'; break;
      }
      return r;
    },
    getStatusColor: function (status) {
      let r;
      switch( status ){
        case 0: r = 'red'; break;
        case 1: r = 'black'; break;
        case 2: r = 'grey'; break;
        case 3: r = 'blue'; break;
        case 4: r = 'green'; break;
        case 5: r = 'silver'; break;
      }
      return r;
    },
    getStatusName: function (status) {
      let r;
      // STATUS: 
      switch(status) {
        case 0: r = 'Wymagana akceptacja'; break;
        case 1: r = 'Oczekuje na magazyn'; break;
        case 2: r = 'Do odbioru u klienta'; break;
        case 3: r = 'Oczekuje na wysyłkę'; break;
        case 4: r = 'Termin uzgodniony z odbiorcą'
        case 5: r = 'Wysłano'; break;
        case 6: r = 'Dostarczono'; break;
        case 7: r = 'Oczekuje na pobranie'; break;
        case 8: r = 'Zarchiwizowano'; break;
      }
      return r;
    },
    base64ToArrayBuffer: function(_base64Str) {
      var binaryString = window.atob(_base64Str);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
     }
     return bytes;
   },
   sendMessage: function() {
     axios({
       url: this.$store.state.url + '/api/ticket/create-message',
       data: {
         token: this.$store.state.token,
         ticket_id: this.$route.params.id,
         content: this.content
       },
       method: 'POST'
     })
     .then( resp => {
        this.content = "";
        this.getMessages();
     })
   },
   getMessages: function() {
     this.allMessages = []
     axios({
       url: this.$store.state.url + '/api/ticket/get-messages',
       data: {
         token: this.$store.state.token,
         ticket_id: this.$route.params.id,
       },
       method: 'POST'
     })
     .then( resp => {
       this.messages = resp.data.messages
       this.messages.forEach( message => {
         message.updated = new Date( Date.parse( message.updated ) ).toLocaleString('pl-PL')

         message.admin = !(message.user_type > 10);
         this.allMessages.push( message )

       })
       this.messages = []
     })
   }
  },
  watch: {
    'ticket': function(val) {
      let images = val.pictures.split(',')

      let that = this;
      images.forEach( image => {
        axios({
          url: that.$store.state.url + '/api/media/image',
          data: {
            token: that.$store.state.token,
            file_id: image
          },
          method: 'POST'
        })
        .then( resp => {
          let byte = that.base64ToArrayBuffer( resp.data.image )
          let blob = new Blob([byte], {
            type: 'image/jpeg'
          })
          that.images.push({
            base64: resp.data.image,
            blob: URL.createObjectURL(blob)
          })
        })
      })
    },
  },
  updated() {
    refreshFsLightbox();
  },
  created() {
    axios({
      url: this.$store.state.url + '/api/ticket/get',
      data: {
        token: this.$store.state.token,
        params: {
          ticket_id: this.$route.params.id
        },
        transport: true
      },
      method: 'POST'
    })
    .then( resp => {

      let t = resp.data.transport

      let d = new Date( Date.parse( t.date_created ) ) ;

      this.transport = {
        tid: t.transport_id,
        t_number: t.t_number,
        content: t.content,
        info: t.info,
        params: (t.packages) ? JSON.parse(t.packages) : null,
        price: t.price,
        inAdvance: t.payed_in_advance ? true : false,
        created: d.toLocaleString('pl-PL'),
        expected: t.date_expected ,
        value: t.price + ' zł',
        status: t.status
      }

      this.ticket = resp.data.tickets[0]
      this.ticket.updated = new Date( Date.parse( this.ticket.updated ) ).toLocaleString('pl-PL') ;
    })

    this.getMessages()
  },

}
</script>
<style>
.images{
  display:flex;
}
.ticketImage{
  margin:5px;
  width:250px;
}
</style>
